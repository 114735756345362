import { useEffect, useState } from 'react';

import { localStorage } from '~src/state/localStorage';
import { location } from '~src/util';

export const useEnvironment = () => {
  const [environment, setEnvironment] = useState({
    isDebug: false,
    isLocalhost: false,
    isProduction: false,
    isStaging: false,
  });

  const { href } = location ?? {};

  useEffect(() => {
    const isDebug = localStorage.get('debug') === 'true';
    const isLocalhost = !!href?.includes('localhost');
    const isStaging = !!href?.includes('staging');
    const isProduction = !isLocalhost && !isStaging;

    setEnvironment({ isDebug, isLocalhost, isProduction, isStaging });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return environment;
};
