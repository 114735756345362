import levenshtein from 'fast-levenshtein';
export const sortSuggestionsBySimilarity = (array, input) => {
    return array.sort((a, b) => {
        const inputMatch = input.toLowerCase();
        const similarA = similiarityBetweenStrings(a.forslagstekst.toLowerCase(), inputMatch);
        const similarB = similiarityBetweenStrings(b.forslagstekst.toLowerCase(), inputMatch);
        return similarB - similarA;
    });
};
export const similiarityBetweenStrings = (s1, s2) => {
    const firstStringShortest = s1.length > s2.length;
    const longer = firstStringShortest ? s1 : s2;
    const shorter = firstStringShortest ? s2 : s1;
    return longer.length === 0 ? 1 : (longer.length - levenshtein.get(longer, shorter)) / longer.length;
};
