import { type AppProps } from 'next/app';
import Head from 'next/head';
import { StrictMode } from 'react';

import { initializeBuilder } from '~src/builder';
import { RootLayout } from '~src/layouts/RootLayout';

import '~src/style/base.css';

initializeBuilder().catch(console.error);

export default function App({ Component, pageProps }: AppProps) {
  return (
    <StrictMode>
      <RootLayout>
        <Head>
          <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        </Head>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...pageProps} />
      </RootLayout>
    </StrictMode>
  );
}
