import { QueryClientProvider } from '@tanstack/react-query';

import { ThirdPartyScripts } from '~src/components/ThirdPartyScripts';
import { queryClient } from '~src/services/client';

type RootLayoutProps = { children: JSX.Element | JSX.Element[] | null };

export const RootLayout = ({ children }: RootLayoutProps) => (
  <QueryClientProvider client={queryClient}>
    <ThirdPartyScripts />

    {children}
  </QueryClientProvider>
);
