import clsx from 'clsx';
import { ReactNode, useState } from 'react';

import { DAWAAddress } from '~src/model';

import styles from './AutoSuggest.module.css';

const { active, autoSuggest, iconContainer, suggestionsShown, withIcon } = styles;

const MAX_SUGGESTIONS = 6;

type Suggestion = Partial<DAWAAddress> & { name: string };

type AutoSuggestProps<T extends Suggestion> = {
  icon?: ReactNode;
  onChange: (value?: string) => void;
  onSelect: (value?: T) => void;
  suggestions: T[];
  value?: string;
  placeholder?: string;
  elementID?: string;
};

export const AutoSuggest = <T extends Suggestion>({
  onChange,
  onSelect,
  suggestions,
  value,
  placeholder,
  icon,
  elementID,
}: AutoSuggestProps<T>) => {
  const [focusCursorIndex, setFocusCursorIndex] = useState(-1);

  const decrementCursor = () => {
    setFocusCursorIndex(Math.max(focusCursorIndex - 1, -1));
  };

  const incrementCursor = () => {
    setFocusCursorIndex(Math.min(focusCursorIndex + 1, suggestions.length - 1, MAX_SUGGESTIONS - 1));
  };

  const resetCursor = () => {
    setFocusCursorIndex(-1);
  };

  return (
    <div id={elementID} className={clsx([autoSuggest, icon && withIcon])}>
      {icon && <div className={iconContainer}>{icon}</div>}

      <input
        className={clsx(suggestions.length > 0 && suggestionsShown)}
        onChange={({ target }) => {
          onChange(target.value);
          resetCursor();
        }}
        placeholder={placeholder}
        onKeyDown={({ code }) => {
          if (code === 'ArrowDown') {
            incrementCursor();
          }
          if (code === 'ArrowUp') {
            decrementCursor();
          }
          if (code === 'Enter') {
            onSelect(suggestions[focusCursorIndex]);
            resetCursor();
          }
        }}
        type='text'
        value={value}
      />

      {suggestions?.length > 0 && (
        <ul>
          {suggestions.slice(0, MAX_SUGGESTIONS)?.map((item, index) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
            <li
              className={index === focusCursorIndex ? active : ''}
              key={item.name}
              onClick={() => {
                onSelect(item);
                resetCursor();
              }}
            >
              {item.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
