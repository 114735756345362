/* eslint-disable react/jsx-props-no-spreading */

import Script from 'next/script';

import { useAdvertisementScripts, useCustomerChatScripts, useTrackingScripts } from '~src/hooks';
import { useCheckBuilderPreview } from '~src/util';

export const ThirdPartyScripts = () => {
  const trackingScripts = useTrackingScripts();
  const advertisementScripts = useAdvertisementScripts();
  const chatScripts = useCustomerChatScripts();

  const scripts = [...trackingScripts, ...advertisementScripts, ...chatScripts];

  const isBuilderPreview = useCheckBuilderPreview();

  return (
    <>
      {!isBuilderPreview && (
        <Script
          defer
          id='cookieyes'
          type='text/javascript'
          src='https://cdn-cookieyes.com/client_data/74ccc4d9b597d05ce0a6b8bd/script.js'
        />
      )}

      {scripts.map(({ async, defer, src }) => (
        <Script async={async} defer={defer} key={src} src={src} />
      ))}
    </>
  );
};
