import { useMemo } from 'react';

import { CALCULATOR_URL } from '~src/config';

export const { location, localStorage: globalLocalStorage } = globalThis;

export const redirectToCalculator = (id: string | number) => {
  location.href = `${CALCULATOR_URL}?addressID=${id}`;
};

export const useCheckBuilderPreview = () =>
  useMemo(() => {
    if (!location) {
      return false;
    }

    return location.href.includes('builder.preview');
  }, []);
