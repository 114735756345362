import { useEffect, useMemo } from 'react';

import { sortSuggestionsBySimilarity } from '@bodilenergy/address';

import {
  isDAWAAddress,
  redirectToCalculator,
  toAddressFromAddressDescription,
  toAddressFromDAWAAddress,
} from '~src/util';

import { AutoSuggest } from '../AutoSuggest';
import { useAddressLookup } from './hooks/useAddressLookup';

const MAX_ADDRESS_SUGGESTIONS = 6;

type AddressFieldProps = { placeholder?: string; elementID?: string };

export const AddressField = ({ placeholder, elementID }: AddressFieldProps) => {
  const { input, setInput, suggestions, resetSuggestions } = useAddressLookup();

  useEffect(
    function resetSuggestionsForEmptyInput() {
      if (!input) {
        resetSuggestions();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [input]
  );

  const topSuggestions = useMemo(
    () =>
      sortSuggestionsBySimilarity(suggestions, input)
        .slice(0, MAX_ADDRESS_SUGGESTIONS)
        .map(({ tekst, data, type }, index) => ({
          value: `${index}`,
          label: tekst.replace(/, ,/g, ','),
          data,
          type,
        })),

    [suggestions, input]
  );

  return (
    <AutoSuggest
      elementID={elementID}
      placeholder={placeholder}
      onChange={(newSearch?: string) => {
        setInput(newSearch ?? '');
      }}
      onSelect={async item => {
        if (!item) {
          return;
        }

        const { label, type, data } = item;

        setInput(`${label} `);

        if (type === 'adgangsadresse') {
          const addressResult = await toAddressFromAddressDescription(label);

          if (addressResult?.id) {
            redirectToCalculator(addressResult.id);
          }
        }

        if (type === 'adresse') {
          const address = isDAWAAddress(data) ? toAddressFromDAWAAddress(data) : undefined;

          if (address?.id) {
            redirectToCalculator(address.id);
          }
        }
      }}
      suggestions={topSuggestions.map(item => ({ ...item, name: item.label }))}
      value={input}
    />
  );
};
