import { useEffect, useState } from 'react';

import { globalLocalStorage } from '~src/util';

export const useIsClient = () => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(!!globalLocalStorage);
  }, []);

  return isClient;
};
