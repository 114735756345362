import { fetchAddressSuggestions as suggestAddresses } from '@bodilenergy/address';

import { DAWAAutocompleteSuggestion } from '~src/model';

export const fetchAddressSuggestions = async (searchAddress?: string): Promise<DAWAAutocompleteSuggestion[]> => {
  if (!searchAddress) {
    return [];
  }

  return suggestAddresses(searchAddress);
};
