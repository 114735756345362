import { useCookie } from '~src/hooks/useCookie';

const CONSENT_COOKIE_NAME = 'cookieyes-consent';
const ADVERTISEMENT_CONSENT_SUBSTRING = 'advertisement:yes';
const ANALYTICS_CONSENT_SUBSTRING = 'analytics:yes';

export const useCookieConsent = () => {
  const { cookie } = useCookie(CONSENT_COOKIE_NAME);

  const isAdvertisementAllowed = cookie?.includes(ADVERTISEMENT_CONSENT_SUBSTRING);
  const isAnalyticsAllowed = cookie?.includes(ANALYTICS_CONSENT_SUBSTRING);

  return { isAdvertisementAllowed, isAnalyticsAllowed };
};
