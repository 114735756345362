import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';

import { DAWAAutocompleteSuggestion } from '~src/model/address';
import { fetchAddressSuggestions } from '~src/services/dawa/dawaService';

const MINIMUM_SEARCH_LENGTH = 2;

const UPDATE_INTERVAL_MS = 333;

export const useAddressLookup = () => {
  const [input, setInput] = useState('');
  const [suggestions, setSuggestions] = useState<DAWAAutocompleteSuggestion[]>([]);

  useEffect(
    function fetchSuggestionsOnInputChange() {
      fetchSuggestions(input);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [input]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchSuggestions = useCallback(
    debounce(async newInput => {
      if ((newInput?.length ?? 0) < MINIMUM_SEARCH_LENGTH) {
        return;
      }

      const data = await fetchAddressSuggestions(newInput);

      setSuggestions(data);
    }, UPDATE_INTERVAL_MS),
    []
  );

  return {
    suggestions,
    resetSuggestions: () => setSuggestions([]),
    input,
    setInput,
  };
};
