import '@builder.io/widgets';

import { Builder, builder } from '@builder.io/react';

import { BUILDER_API_KEY } from '~src/config';

import { AddressField } from './components/AddressField';
import { BookingForm } from './components/BookingForm';
import { ContactMeForm } from './components/ContactMeForm';

const COMPONENT_IMAGE = 'https://tabler-icons.io/static/tabler-icons/icons-png/home.png';

export const initializeBuilder = async () => {
  await builder.init(BUILDER_API_KEY);

  Builder.registerComponent(AddressField, {
    name: 'AddressField',
    image: COMPONENT_IMAGE,
    inputs: [
      { name: 'placeholder', type: 'text' },
      { name: 'elementID', type: 'text' },
    ],
  });

  Builder.registerComponent(ContactMeForm, {
    name: 'ContactMeForm',
    image: COMPONENT_IMAGE,
    inputs: [{ name: 'isCompany', type: 'boolean' }],
  });

  Builder.registerComponent(BookingForm, {
    name: 'BookingForm',
    image: COMPONENT_IMAGE,
    inputs: [{ name: 'url', type: 'text' }],
  });
};
