import { ADDRESS_BASE_URL, DAWA_OPTIONS } from './constants';
const getAutoCompleteUrl = (search) => `${ADDRESS_BASE_URL}/autocomplete?q=${search}&fuzzy=true&type=adresse`;
export const fetchAddressSuggestions = async (searchAddress) => {
    if (!searchAddress) {
        return [];
    }
    const response = await fetch(getAutoCompleteUrl(searchAddress), DAWA_OPTIONS);
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return response.json();
};
