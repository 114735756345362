import { useEnvironment } from '~src/hooks/useEnvironment';
import { Script } from '~src/model';

import { useCookieConsent } from './useCookieConsent';

const HOMEPAGE_ADVERTISEMENT_SCRIPTS = [{ defer: true, src: '/static/scripts/meta-pixel.js' }];

export const useAdvertisementScripts = (): Script[] => {
  const { isDebug, isProduction } = useEnvironment();

  const { isAdvertisementAllowed } = useCookieConsent();

  if (!isAdvertisementAllowed || isDebug || !isProduction) {
    return [];
  }

  return HOMEPAGE_ADVERTISEMENT_SCRIPTS;
};
