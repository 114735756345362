/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useContactMe } from '~src/services/useContact';

import styles from './ContactMeForm.module.css';

const {
  button,
  checkboxWrapper,
  contactMeForm,
  disabledButton,
  formError,
  inputWrapper,
  label,
  phoneNumberInputWrapper,
  phoneNumberPrefix,
  red,
  submitConfirmationWrapper,
} = styles;

// Empty placeholder use to to check if input field has value
const PLACEHOLDER = ' ';

export type FormValues = {
  name: string;
  email: string;
  phoneNumber?: string;
  inquiry: string;
};

type ContactMeFormProps = { isCompany?: boolean };

export const ContactMeForm = ({ isCompany }: ContactMeFormProps) => {
  const [consent, setConsent] = useState(false);

  const { contactMe } = useContactMe();

  const { register, handleSubmit, formState, reset } = useForm<FormValues>({
    mode: 'onSubmit',
  });

  const { errors, isSubmitSuccessful } = formState;

  const { name: nameError, email: emailError, inquiry: inquiryError, phoneNumber: phoneNumberError } = errors;

  return (
    <div className={contactMeForm}>
      <form
        onSubmit={handleSubmit((formData: FormValues) => {
          contactMe({ ...formData, isCompany });
          reset();
        })}
        method='post'
        noValidate
      >
        <div className={inputWrapper}>
          <input type='text' placeholder={PLACEHOLDER} {...register('name', { required: true })} />

          <p className={label}>
            Navn <span className={red}>*</span>
          </p>

          {nameError && <p className={formError}>Indtast navn</p>}
        </div>

        <div className={inputWrapper}>
          <input
            type='email'
            placeholder={PLACEHOLDER}
            {...register('email', { required: true, pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/ })}
          />

          <p className={label}>
            E-mail <span className={red}>*</span>
          </p>

          {emailError && <p className={formError}>Indtast e-mail</p>}
        </div>

        <div className={phoneNumberInputWrapper}>
          <p className={phoneNumberPrefix}>+45</p>

          <input type='tel' placeholder={PLACEHOLDER} {...register('phoneNumber', { pattern: /[0-9]{8}/ })} />

          <p className={label}>Telefonnummer</p>

          {phoneNumberError && <p className={formError}>Indtast telefonnummer</p>}
        </div>

        <div className={inputWrapper}>
          <textarea placeholder={PLACEHOLDER} {...register('inquiry', { required: true })} />

          <p className={label}>
            Besked <span className={red}>*</span>
          </p>

          {inquiryError && <p className={formError}>Indtast dit besked</p>}
        </div>

        <div className={checkboxWrapper}>
          <input type='checkbox' onClick={() => setConsent(!consent)} />

          <p>Jeg accepterer at Bodil Energi må kontakte mig</p>
        </div>

        <button className={clsx(button, !consent && disabledButton)} disabled={!consent} type='submit'>
          Send besked
        </button>

        {isSubmitSuccessful && (
          <div className={submitConfirmationWrapper}>
            Tak for dit spørgsmål, vi vender tilbage til dig hurtigst muligt!
          </div>
        )}
      </form>
    </div>
  );
};
