import { useEnvironment } from '~src/hooks/useEnvironment';
import { Script } from '~src/model';

import { useCookieConsent } from './useCookieConsent';
import { useGtagConsent } from './useGtagConsent';

const GTAG_SCRIPTS = [
  { defer: true, src: `/static/scripts/gtag-homepage.js` },
  { defer: true, src: `/static/scripts/gtag-ads.js` },
];

const HOMEPAGE_TRACKING_SCRIPTS = [
  { defer: true, src: `/static/scripts/clarity.js` },
  { defer: true, src: '/static/scripts/gclid.js' },
  { defer: true, src: '/static/scripts/gtm.js' },
  { defer: true, src: '/static/scripts/ac-tracking.js' },
  { defer: true, src: '/static/scripts/hotjar.js' },
  { defer: true, src: '/static/scripts/utm.js' },
];

export const useTrackingScripts = (): Script[] => {
  const { isDebug, isProduction } = useEnvironment();

  const { isAnalyticsAllowed, isAdvertisementAllowed } = useCookieConsent();

  useGtagConsent({
    adStorage: isAdvertisementAllowed,
    adPersonalization: isAdvertisementAllowed,
    analyticsStorage: isAnalyticsAllowed,
    adUserData: isAdvertisementAllowed,
  });

  if (isDebug || !isProduction) {
    return [];
  }

  if (!isAnalyticsAllowed) {
    return GTAG_SCRIPTS;
  }

  return [...HOMEPAGE_TRACKING_SCRIPTS, ...GTAG_SCRIPTS];
};
