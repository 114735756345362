import { globalLocalStorage } from '~src/util';

type LocalStoreKey = 'debug' | 'gclid' | 'utmFields';

export const localStorage = {
  get: (key: LocalStoreKey) => globalLocalStorage?.getItem(key) ?? undefined,
  clear: (key: LocalStoreKey) => globalLocalStorage?.removeItem(key),
  clearAll: () => globalLocalStorage?.clear(),
  set: (key: LocalStoreKey, data: string) => globalLocalStorage?.setItem(key, data),
};
