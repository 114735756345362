export const useLocalization = () => translate;

const translate = {
  ALL: 'Alle',
  ANNUAL_PRODUCTION: 'Årlig produktion',
  BRAND: 'Mærke',
  BUFFER: 'Buffertank',
  CAPACITY: 'Kapacitet',
  CATEGORY: 'Kategori',
  COP: 'COP (varmt vand)',
  DATA_SHEET: 'Datablad',
  DIMENSIONS_CYLINDER: 'H x D (cm)',
  DIMENSIONS_BOX: 'H x B x D (cm)',
  EFFECT: 'Effekt',
  EFFICIENCY: 'Effektivitet',
  NOISE_LEVEL: 'Lydniveau',
  PRODUCT_IMAGE_ALT: 'Produktbillede',
  PRODUCT_TYPE: 'Produkttype',
  SCOP: 'SCOP',
  TEMPERATURE_OUT: 'Max. fremløbs-temperatur',
  TYPE: 'Type',
  WATER_VOLUME: 'Vandtank',
  WEIGHT: 'Vægt',
};
