import { GraphQLClient } from 'graphql-request';

import { QueryClient } from '@tanstack/react-query';

import { API_URL } from '~src/config';

export { QueryClientProvider, useQuery, useQueryClient } from '@tanstack/react-query';

const ONE_MINUTE = 60 * 1000;

const GRAPHQL_URL = `${API_URL}/graphql/v1`;

export const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: ONE_MINUTE, retry: 1, refetchOnWindowFocus: false } },
});

export const graphQLClient = new GraphQLClient(GRAPHQL_URL, {
  keepalive: true,
});

export const FETCH_OPTIONS: RequestInit = { headers: { Connection: 'keep-alive' } };
